import React from 'react'
import styled from 'styled-components'

const ListUl = styled.ul`
    column-gap: 64px;
    padding-left: 32px;
    color: inherit;
    margin: 0px;
`;
const ListLi = styled.li`
    position: relative;
    padding: 4px 0px;
    break-inside: avoid;
    width: 100%;
    color:
    rgb(45, 47, 61);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6;
    margin: 0px 0px 8px;
    font-size: 18px;

    :before {
        content: "●";
        font-family: "Arial, Helvetica, sans-serif";
        display: inline-block;
        position: absolute;
        left: -24px;
        top: 10px;
        font-size: 12px;
        color:
        rgb(0, 79, 239);
    }
`;

const List = props => (

<ListUl>
    <ListLi>
        {props.listitem1}
    </ListLi>
    <ListLi>
        {props.listitem2}
    </ListLi>
    <ListLi>
        {props.listitem3}
    </ListLi>
    <ListLi>
        {props.listitem4}
    </ListLi>
    <ListLi>
        {props.listitem5}
    </ListLi>
</ListUl>

)
export default List