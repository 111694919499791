import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'


const OfferSection = styled.section`
    background-color: rgb(61, 220, 151);
    margin-bottom: 40px;
`;

const OfferDiv = styled.div`
    display: inherit;
    width: 100%;
    max-width: 1312px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 24px 0px;
`;

const OfferContainer= styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    flex-basis: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    justify-content: left;

    p {
        text-align: left;
        margin: 0;
    }
    @media only screen and (min-width:0em) {
        flex-basis: 100%;
        max-width: 100%;
        display: block;
    }

    @media only screen and (min-width:62em) {
        flex-basis: 75%;
        max-width: 75%;
        display: flex;
        justify-content: left;
      }

      @media (min-width:768px) {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      @media (max-width: 575px){
        padding: 0px 24px;
      }
`;
const OfferHeader = styled.h2`
    text-align: left;
    color: rgb(45, 47, 61);
    font-weight: 500;
    font-size: 18px;
    display: block;
    @media (min-width:576px) {
        font-size: 32px;
    }
`;

const OfferButtonContainer = styled.div`
  margin-top: 34px;
  padding-bottom: 5px;
  display: block;
`;

const OfferButton = styled(Link)`
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    min-width: auto;
    padding: 12px 24px;
    pointer-events: auto;
    text-decoration: none;
    width: auto;
    text-align: center;
    background-position: center center;
    transition: background 0.8s ease 0s;
    box-shadow: rgb(252, 252, 252) 0px 0px 0px 2px inset;
    background-color: rgb(252, 252, 252);
    display: inline-block;
    position: relative;
    background-image: none;

    @media (max-width: 575px){
      width: 100%;
    }

    :hover,
    :active,
    :focus {
        background: rgb(232, 232, 232) radial-gradient(circle, transparent 1%, rgb(232, 232, 232) 1%) center center / 15000%;
    }

`;


const OfferLight = ({ headline, body, target, currentlanguage, buttontext }) => (
    <OfferSection>
        <OfferDiv>
            <OfferContainer>
                <div>
                    <OfferHeader>{headline}</OfferHeader>
                    <p>{body}</p>
                    <OfferButtonContainer>
                    {currentlanguage === "de" &&
                        <OfferButton to={target}>{buttontext}</OfferButton>
                    }
                    {currentlanguage === "en" &&
                        <OfferButton to={target}>Find out More</OfferButton>
                    }        
                </OfferButtonContainer>    
                </div>
            </OfferContainer>
        </OfferDiv>
    </OfferSection>
  )
  
  
  
export default OfferLight