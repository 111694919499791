import React, { useState } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/seo/mainseo'
import PageBannerOG from '../components/pagebanner/pagebanner'
import MainNav from '../components/navigation/mainnav'
import PageBannerMessage from '../components/pagebanner/pagebannermessage'
import ContentBlock from '../components/contentblock'
import ContentBlockBackground from '../components/contentblockbackground'
import Container from '../components/container'
import CenteringDiv from '../components/centeringdiv'
import CourseAccordion from '../components/courseaccordion'
import ContentContainer from '../components/contentcontainer'
import PageBlock from '../components/pageblock'
import PageContact from '../components/forms/pagecontact-sf'
import BreadCrumbs  from '../components/breadcrumbs'
import BreadCrumbsTopLevel  from '../components/breadcrumbstoplevel'
import OfferLight from '../components/offerlight'
import FeatureCardList from '../components/cards/featurecardlist'

const EnglischBeruf = ({ data, html }) => {
  const { markdownRemark: post } = data
  var delink = "de";
  var enlink = "en";
  const metaUrlalt="https://www.berlitz-augsburg.de/"
  const metaUrl="https://www.berlitz-augsburg.de"

  if (post.frontmatter.language === "de"){
    delink = `${metaUrl}${post.fields.slug}`
    enlink = `${metaUrlalt}${post.frontmatter.seo.altlanguagelink}`;
  } else if (post.frontmatter.language === "en") {
    delink = `${metaUrlalt}${post.frontmatter.seo.altlanguagelink}`;
    enlink = `${metaUrl}${post.fields.slug}`
  }
  const [course, setCourse] = useState("");
  return (
    <Layout currentlang={post.frontmatter.language} langlinkde={delink} langlinken={enlink}>
      <Seo language={post.frontmatter.language}
           pageslug={post.fields.slug}
           title={post.frontmatter.seo.metatitle}
           imgtitle={post.frontmatter.title}
           imgtitleen={post.frontmatter.imagetitle}
           description={post.frontmatter.seo.metadescription}
           lastbreadcrumb={post.frontmatter.lastbreadcrumb}
           pagetype={post.frontmatter.templateKey}
           altlanglink={post.frontmatter.seo.altlanguagelink}
           hreflangalt={post.frontmatter.seo.hreflangalt}
      />
      <PageBannerOG title={post.frontmatter.title} alt={post.frontmatter.displaytitle}>
        <MainNav langlinkde={delink} langlinken={enlink} currentlang={post.frontmatter.language}/>
        <PageBannerMessage supertitle={post.frontmatter.supertitle} pageTitle={post.frontmatter.displaytitle} pageSubtitle={post.frontmatter.subtitle}/>
      </PageBannerOG>
      {post.frontmatter.toplevelpage === true &&
      <BreadCrumbsTopLevel current={post.frontmatter.lastbreadcrumb}
                   currentlang={post.frontmatter.language}
                   breadcrumbcolor={post.frontmatter.breadcrumbcolor} 
      />
      }
      {post.frontmatter.toplevelpage === false &&
      <BreadCrumbs breadcrumbs={post.frontmatter.breadcrumbs} 
                  current={post.frontmatter.lastbreadcrumb}
                  currentlang={post.frontmatter.language}
                  breadcrumbcolor={post.frontmatter.breadcrumbcolor}  
      />
      }

      <section role="main">
        <ContentBlockBackground>
          <Container>
            <CenteringDiv>
              <ContentContainer>
                {post.frontmatter.leadinmult.map(item => (
                    <p>{item.paragraph}</p>
                ))}
              </ContentContainer>
            </CenteringDiv>  
          </Container>
        </ContentBlockBackground>
        <Container>   
          <CenteringDiv>
            <ContentContainer dangerouslySetInnerHTML={{ __html: post.html }}/>
          </CenteringDiv>        
        </Container>
        {post.frontmatter.callout  &&
          <React.Fragment>
              {post.frontmatter.callout.map(item => (
                <OfferLight headline={item.headline}
                            body={item.body}
                            target={item.target}
                            currentlanguage={item.language}
                            buttontext={item.buttontext}   
                />
              ))}
          </React.Fragment>
        }
        {post.frontmatter.featurecardlist &&        
          <ContentBlock>
            <Container>
                <FeatureCardList cardtitle={post.frontmatter.featurecardlist.cardtitle}
                                 carditems={post.frontmatter.featurecardlist.listitems}
                                 cardimage={post.frontmatter.featurecardlist.featurecardimage.childImageSharp.fixed}
                                 imageposition={post.frontmatter.featurecardlist.imageposition}
                                 buttons={post.frontmatter.featurecardlist.buttons}
                                 languageselect={post.frontmatter.featurecardlist.languageselect}
                                 singlebutton={post.frontmatter.featurecardlist.singlebutton}
                                 singlebuttonlink={post.frontmatter.featurecardlist.singlebuttonlink}
                />
            </Container>
          </ContentBlock>
        }
          <ContentBlockBackground>
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  {post.frontmatter.pagecontentblocks.map(item => (
                    <PageBlock         
                      heading={item.heading}
                      text={item.text}
                    />
                  ))}
                </ContentContainer>
              </CenteringDiv>
            </Container>
          </ContentBlockBackground>
        <PageContact currentlang={post.frontmatter.language}
                     leadsource={post.fields.slug}
                     pagetype={post.frontmatter.pagetype}
                     anchor="kursanfrage"
                     anchoren="course-inquiry" 
                     selectedcourse={course} 
        />       
      </section>
    </Layout>
  )
}

export default EnglischBeruf

export const pageQuery = graphql`
  query EfBByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        templateKey
        pagetype
        languageinterest
        subfolder
        toplevelpage
        language
        callout {
          headline
          body
          target
          buttontext
          language
        }
        featurecardlist {
          cardtitle
          listitems {
            listitem
          }
          buttons {
            button
            buttonlink
          }
          singlebutton
          singlebuttonlink
          imageposition
          languageselect
          featurecardimage {
            childImageSharp {
              fixed(width: 720, height: 720, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        seo {
          metatitle
          metadescription
          altlanguagelink
          hreflangalt
        }
        title       
        displaytitle
        supertitle
        breadcrumbs {
          crumbitem
          link
        }
        lastbreadcrumb
        breadcrumbcolor
        pagebody
        leadinmult {
          paragraph
        }
        pagecontentblocks {
          heading
          text
        }
        accordionheading
        accordionleadin
        modules {
          modtitle
          modtermine
          modulecontent {
            contentitem
          }
          modtext
          modlength
          modtrainer
          modzert
          modcosts
          modreg
        }
      }
    }
  }
  
`
