import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel,} from 'react-accessible-accordion'
import List from '../components/list'

const AccordionButton = styled(AccordionItemButton)`
  padding: 20px 0;
  cursor: pointer;
  width: 100%;
  text-align: inherit;
  outline: none;
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  h3 {
    color: #004FEF;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 15px;
  }

  @media (min-width:768px) {
      outline-color: -webkit-focus-ring-color;
      outline-style: none;

      h3 {
        font-size: 18px;
      }
  }
  ::after {
    display: inline-block;
    content: '';
    height: 8px;
    width: 8px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    color: rgb(29, 29, 29);
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
  &[aria-expanded='true']::after {
    transform: rotate(-135deg);
    transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
`;
const AccordionHeading = styled(AccordionItemHeading)`
  display: block;
  border-bottom: 1px solid #F6F6F6;
  color: rgb(29, 29, 29);
  outline-style: none !important;
`;
const AccordionPanel = styled(AccordionItemPanel)`
padding: 30px;
border-bottom: 1px solid #F6F6F6;
background-color: rgb(252,252,252);

h4 {
  font-weight: 500;
}
`;

const CourseButton = styled.a`
  width: 100%;
  text-align: center;
  background: none;
  background-color: #004FEF;
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  text-decoration: none;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: auto;
  color: #FFFFFF;
  position: relative;
  text-transform: none;
  display: block;
  margin-top: 10px;

  :hover,
  :active,
  :focus {
    background-color: rgb(25, 96, 240);
    border: none;
    color: #FFFFFF;
  }
  @media only screen and (min-width:62em) {
    width: 150px;
    margin-top: -6px;
    span {
      display: none;
    }
  }
  
`;

const ListUl = styled.ul`
    column-gap: 64px;
    padding-left: 32px;
    color: inherit;
    margin: 0px;
`;
const ListLi = styled.li`
    position: relative;
    padding: 4px 0px;
    break-inside: avoid;
    width: 100%;
    color:
    rgb(45, 47, 61);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6;
    margin: 0px 0px 8px;
    font-size: 18px;

    :before {
        content: "●";
        font-family: "Arial, Helvetica, sans-serif";
        display: inline-block;
        position: absolute;
        left: -24px;
        top: 10px;
        font-size: 12px;
        color:
        rgb(0, 79, 239);
    }
`;


function CourseAccordion(props)  {

var anchorurl = '/berufliche-weiterbildung/englisch-fuer-den-beruf/#kursanfrage';
var anchorurlen ='/en/learn-english/english-for-work/#course-inquiry'
const {assignCourse} = props;


return (

<Accordion preExpanded="0">
  {props.currentlang === "de" &&
    <React.Fragment>
      {props.coursemodules.map((item,index) => (
        <AccordionItem uuid={index}>
          <AccordionHeading>
            <AccordionButton>
              <h3>{item.modtitle}</h3>
            </AccordionButton>
          </AccordionHeading>
          <AccordionPanel>
            <h4>Termine</h4>
              <p>{item.modtermine}</p>
            <h4>Kursinhalte</h4>
            <ListUl>
            {item.modulecontent.map(item => (
              <ListLi>
                {item.contentitem}
              </ListLi>
            ))}
            </ListUl> 
            <p>{item.modtext}</p>
            <h4>Sprachtrainer</h4>
            <p>{item.modtrainer}</p>
            <h4>Abschluss</h4>
            <p>{item.modzert}</p>
            <h4>Dauer</h4>
            <p>{item.modlength}</p>
            <h4>Kosten</h4>
            <p>{item.modcosts}</p>
            <h4>Beratung und Anmeldung</h4>
            <p>{item.modreg}</p>

              <CourseButton href={anchorurl}><button onClick={() => assignCourse(item)}>Anfrage schicken</button></CourseButton>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </React.Fragment>
  }
  {props.currentlang === "en" &&
    <React.Fragment>
      {props.coursemodules.map((item,index) => (
        <AccordionItem uuid={index}>
          <AccordionHeading>
            <AccordionButton>
              <h3>{item.modtitle}</h3>
            </AccordionButton>
          </AccordionHeading>
          <AccordionPanel>
            <h4>Starting Dates</h4>
              <p>{item.modtermine}</p>
            <h4>Course Content</h4>
            <ListUl>
            {item.modulecontent.map(item => (
              <ListLi>
                {item.contentitem}
              </ListLi>
            ))}
            </ListUl> 
            <p>{item.modtext}</p>
            <h4>Trainer</h4>
              <p>{item.modtrainer}</p>
            <h4>Course Length</h4>
              <p>{item.modlength}</p>
            <h4>Certificate</h4>
              <p>{item.modzert}</p>
            <h4>Course Costs</h4>
              <p>{item.modcosts}</p>
            <h4>Course Counselling and Registration</h4>
              <p>{item.modreg}</p>

              <CourseButton href={anchorurlen}><button onClick={() => assignCourse(item)}>Send Inquiry</button></CourseButton>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </React.Fragment>
  }  
</Accordion>
)
}
export default CourseAccordion